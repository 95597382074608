.live-train-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.live-train-container .input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.live-train-container .input-container input[type="text"],
.live-train-container .input-container button {
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

.live-train-container .input-container input[type="text"] {
  flex: 1;
  margin-right: 10px;
}

.live-train-container .input-container button {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  flex: none;
}

.live-train-container .error-message {
  color: red;
  margin-bottom: 10px;
}

.live-train-container .card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.live-train-container .card {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.live-train-container .card h4 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 18px;
}

.live-train-container .train-table {
  width: 100%;
  border-collapse: collapse;
  overflow-x: auto;
}

.live-train-container .train-table th,
.live-train-container .train-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.live-train-container .train-table th {
  background-color: #f2f2f2;
}

.live-train-container .train-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.blinking {
  animation: blink 1s infinite;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .live-train-container .input-container {
    flex-direction: column;
    align-items: stretch;
  }

  .live-train-container .input-container input[type="text"],
  .live-train-container .input-container button {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .live-train-container .card-container {
    flex-direction: column;
  }

  .live-train-container .train-table {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .live-train-container .input-container input[type="text"],
  .live-train-container .input-container button {
    padding: 6px 8px;
    font-size: 12px;
  }

  .live-train-container .card h4 {
    font-size: 16px;
  }

  .live-train-container .train-table th,
  .live-train-container .train-table td {
    padding: 6px;
  }
}
