@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles */

* {
  @apply box-border;
}


.error-message {
  @apply text-red-500;
}


body {
  @apply m-0 font-sans bg-gray-100 overflow-x-hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
