/* Normalize styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  overflow-x: hidden;
}

.container {
  position: relative;
  width: 100%;
  min-height: 100vh; /* Ensure container fills the viewport */
  display: flex;
  flex-direction: column;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  z-index: 1000;
}

.main-content {
  display: flex;
  flex: 1;
  margin-top: 110px; /* Adjust based on header height */
  margin-bottom: 60px; /* Adjust based on footer height */
}

.leftbar {
  width: 200px;
  position: fixed;
  top: 110px; /* Adjust based on header height */
  bottom: 60px; /* Adjust based on footer height */
  left: 0;
  background-color: indigo;
  backdrop-filter: blur(10px);
  color: #fff;
  padding: 20px;
  z-index: 900;
  overflow-y: auto;
}

.body-content {
  flex: 1;
  margin-left: 220px; /* Adjust based on leftbar width + some spacing */
  padding: 20px;
  background-color: #fff;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  z-index: 1000;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .leftbar {
    width: 150px;
    padding: 15px;
  }

  .body-content {
    margin-left: 170px;
    padding: 15px;
  }
}



.error-message {
  color: red; /* Set text color to red */
}