/* pnr.css */

.pnr-status {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.pnr-status h2 {
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.pnr-status .input-container {
  display: flex;
  flex-direction: column; /* Stack input and button vertically */
  align-items: center; /* Center align items */
  margin-bottom: 20px;
}
.pnr-status .pnr-data {
  overflow-x: auto; /* Enable horizontal scrolling for the pnr-data container */
}

.pnr-status .input-container input[type="text"] {
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px; /* Added margin bottom for spacing */
}

.pnr-status .input-container button {
  padding: 8px 12px;
  font-size: 14px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.pnr-status .pnr-info {
  margin-top: 20px;
}
.pnr-status .table-container {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  background-color: #f9f9f9;
  overflow-x: auto; /* Add overflow-x: auto to enable horizontal scrolling */
}

.pnr-status .table-container h3 {
  margin-bottom: 10px;
}

.pnr-status table {
  width: 100%;
  border-collapse: collapse;
}

.pnr-status table th, .pnr-status table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}
.pnr-status .pnr-data {
  overflow-x: auto; /* Ensure horizontal scrolling for the pnr-data container */
}

.pnr-status .pnr-data table {
  width: 100%; /* Ensure the table takes full width */
  overflow-x: auto; /* Enable horizontal scrolling for the table */
}

.pnr-status .pnr-data table th, .pnr-status .pnr-data table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}


.pnr-status .error-message {
  color: red;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}

@media (max-width: 600px) {
  .pnr-status h2 {
    flex-direction: column;
  }

  .pnr-status .input-container input[type="text"] {
    width: 100%;
  }

  .pnr-status .input-container button {
    width: 100%;
  }
}
