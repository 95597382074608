/* traininfo.css */

.train-info {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.train-info h2 {
  text-align: center;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.input-container {
  display: flex;
  flex-direction: column; /* Stack input and button vertically */
  align-items: center; /* Center align items */
  margin-bottom: 20px;
}

.input-container input {
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px; /* Added margin bottom for spacing */
}

.input-container button {
  padding: 8px 12px;
  font-size: 14px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.input-container button:hover {
  background-color: #0056b3;
}

.train-info-card {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  margin: 0 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.train-card {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 20px;
}

.train-card h3 {
  margin-top: 0;
}

.card-content p {
  margin: 10px 0;
}

.error {
  color: red;
  text-align: center;
  margin-bottom: 20px;
}

.error-message {
  color: red;
  text-align: center;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .train-info h2 {
    flex-direction: row;
  }

  .input-container input[type="text"] {
    width: 100%;
  }

  .input-container button {
    width: 100%;
  }
}
